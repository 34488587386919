import {Form, Modal, Button, Image} from "react-bootstrap";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
 
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState, useRef} from "react";
 
import {useFormik} from "formik";
// import {createCategory, successConfirmation, updateCategory} from '../../reducers/CategorySlice'
// import {fetchServices} from "../../reducers/ServiceItemSlice";
import { updateRolePermission, createRolePermission } from "../../../reducers/IdentitySlice";
 
const AddRolePermissionComponent = ({data, close, module, role}) => {

    // const [sender, setSender] = useState(false);
    // const [recever, setRecever] = useState(false);
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState();
    const [exitError, setExitError] = useState();

    
      const handleChangea = (event, editor) => {
        const details = editor.getData();
        setEditorData(details);
        setValues({...values, answer: details})
    };
    console.log(data)

    const {values, errors, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: data,

        onSubmit: (values, action) => {

            const res = dispatch(data.update===true?updateRolePermission(values):createRolePermission(values));
            res.then(results => {
                console.log(results)
               if(results.error) {setExitError('Something went wrong. Please try again!'); 

               }
               else {
                close(results.payload.results); 
               }
            }).catch(error => {
                alert('error ---- false')
            });

        },
        //  validationSchema: faqSchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <> <div className="wrap-content">
            <Form onSubmit={handleSubmit} className="form-data">
                <div className="section-two-column faq-form"> 
                  
                        {/* <div className="w-full text-black text-xl font-medium font-['Inter']">Edit Question and Answer </div> */}
                        {/* <hr className="border" /> */}
                        
                        {exitError && <div className="alert alert-danger">
                              {exitError} 
                            </div>}

                       
                            {/* <div className="field-row">
                                <label className="label">Designation Title</label>
                             
                                <input name="title" placeholder="Designation Title" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.title} onChange={handleChange} ></input>
                                 {errors.title && <p className="red">{errors.title}</p>} 
                            </div> */}

                             <div className="field-row">
                            <label>  Worker Designation    </label>
                            <select className={`form-select`} name="workerModuleTextId " defaultValue={values.workerModuleTextId } onChange={handleChange}>
                                <option value=''> select  </option>
                                {module.map((item) => (
                                    <option value={item.textId}> {item.title}</option>))}
                            </select> 
                     
                            {errors.workerModuleTextId && <p className="red">{errors.workerModuleTextId}</p>}  
                        </div>

                        <div className="field-row">
                            <label>Worker Role </label>
                            <select className={`form-select`} name="workerRoleTextId " defaultValue={values.workerRoleTextId } onChange={handleChange}>
                                <option value=''> select  </option>
                                {role.map((item) => (
                                    <option value={item.textId}> {item.title}</option>))}
                            </select> 
                     
                            {errors.workerRoleTextId  && <p className="red">{errors.workerRoleTextId }</p>}  
                        </div>
                       
                          
                        <div className='field-row'> 
                        <Form.Check type="switch" id="read" label="Read" name="read" defaultChecked={values.read === 'Y'? true:false} value={values.read} 
                        onChange={(e)=>{
                            setValues({...values,read:e.target.checked?'Y':'N'});
                            }}/>
                        </div> 
                         <div className='field-row'> 
                        <Form.Check type="switch" id="create" label="Create" name="create" defaultChecked={values.create === 'Y'? true:false} value={values.create} 
                        onChange={(e)=>{
                            setValues({...values,create:e.target.checked?'Y':'N'});
                            }}/>
                        </div> 
                          
                        <div className='field-row'> 
                        <Form.Check type="switch" id="edit" label="Update" name="edit" defaultChecked={values.edit === 'Y'? true:false} value={values.edit} 
                        onChange={(e)=>{
                            setValues({...values,edit:e.target.checked?'Y':'N'});
                            }}/>
                        </div> 
                          
                        <div className='field-row'> 
                        <Form.Check type="switch" id="delete" label="Delete" name="delete" defaultChecked={values.delete === 'Y'? true:false} value={values.delete} 
                        onChange={(e)=>{
                            setValues({...values,delete:e.target.checked?'Y':'N'});
                            }}/>
                        </div> 
{/* 
                      <div className="field-row">
                                <label className="label">Rank</label>
                             
                                <input name="rank" placeholder="Rank" type="number" className="border w-100 border-slate-700 p-2 rounded-md" value={values.rank} onChange={handleChange} ></input>
                                 {errors.rank && <p className="red">{errors.rank}</p>} 
                            </div>
                           */}
                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddRolePermissionComponent;
