import React, {useEffect, useState} from "react";
import CommonLeftSideBar from "./CommonLeftSideBar";
import CommonHeader from "./CommonHeader";
import {Container} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate, useLocation} from 'react-router-dom';
import PageHeader from './PageHeader';
import Firebase from '../firebase/Firebase';
// import SideBarData from "../data/leftSideBar";

import {pageTitle} from "../reducers/LocalDataSlice";
import {appendNavBar, navItemAdd} from "../reducers/NavDataSlice";
import NavigationBar from "./NavigationBar";
import {ArrowRight} from "react-bootstrap-icons";
import BreadcrumbTextComponent from "./BreadcrumbTextComponent";
import FooterComponent from "./FooterComponent";
import {fetchNotificationBellCount} from "../reducers/NotificationSlice";
import {fetchPageMetaInfo, createPageMetaSettings} from "../reducers/DashboardSlice";
import { checkStructure } from '../utility/StringModify';


// function checkStructure(brTree) {
//     console.log('in checkStructure :: ',brTree)
//   if (Array.isArray(brTree)) {
//     console.error('got 1st')
//     return brTree
//   } else if (typeof brTree === 'object') {
//     const result= Object.keys(brTree).map(key => ({
//             name: key,
//             link: brTree[key]
//         }));
//          console.error('got 2nd')
//          return result
//   } else {
//      console.error('got 3rd')
//     return brTree;
//   }
// }



const Layout = ({component, page_title, breadcrumb=false, top_content, pageType}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    // const { navData } = useSelector((state) => state.navData);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [isBreadcrumb, setIsBreadcrumb] = useState(false);

    // const {items} = useSelector((state) => state.category)
    const handleNav = () => setShow(show === false ? true : false);
    const { title, pageId } = useSelector((state) => state.localData)
    const {count, unauthorized} = useSelector((state) => state.notification);
    const { singleMeta, breadcrumbTree, previousTree } = useSelector((state) => state.dashboard);
    // const fullPath = window.location.pathname;
    // const expireDate = localStorage.getItem("expireDate");
    // const currentTime = new Date().getTime();
    const location = useLocation()

 
    
    document.title =  title;
 
    if(unauthorized===false){
        window.location.assign('/logout');
    }
    console.log('pageType**************:',pageType)

    useEffect(() => {
    
        const res = dispatch(fetchPageMetaInfo({'link':location.pathname, "pageType":pageType }))
      
        res.then(results => { 
            if(results.error){ 
                // setTimeout(function(){ setIsBreadcrumb(true)},3000)
            }
        }).catch(error => {
        
            console.error('have error in catch: ', error)
        });
        
    }, [location]);

      
    useEffect(() => {
    //   dispatch(fetchPageMetaInfo(location.pathname))
    console.log('pageId:',pageId,"title:",title);
    console.log('breadcrumbTree:',breadcrumbTree,"previousTree:",previousTree);
    
    if(pageId && !breadcrumbTree.length>0){
        console.log('try to find breadcrumb')
        const brTree= checkStructure(breadcrumb?breadcrumb:previousTree)
        const withCurrent= breadcrumb?breadcrumb:brTree.concat([{"name":title,"link":pageId}])
        // console.warn("withCurrent", breadcrumb)
        // dispatch(createPageMetaSettings({"title":title, "breadcrumbTree":JSON.stringify(checkStructure(withCurrent)),"pageUrl":location.pathname}))
        setIsBreadcrumb(false)
    }
    }, [ pageId ]); 

    useEffect(() => {
        const res = dispatch(fetchNotificationBellCount());
        res.then((results) => {   
            if(results.error){
            // console.log('results in layout::',results.error)

            }
        })
            res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [count]); 
    // console.log('in layout :: ',typeof(singleMeta.breadcrumbTree))
    // console.log('in layout :: ', window.location.href)
    // console.log('in layout :: ', window.location.pathname)
    // console.log('in layout :: ',typeof(JSON.parse(singleMeta.breadcrumbTree)))
    // console.log('in breadcrumbTree :: ',breadcrumbTree)

 
        
    return (
        <>
            <header>
               
                <div className="header-wrapper">
                    <div className={show ? 'menu-icon selected' : 'menu-icon'} onClick={handleNav}>
                        <div className='centered'>
                            {show ? <>
                                    <div className="hr_bar"></div>
                                    <div className="hr_bar"></div>
                                    <div className="hr_bar"></div>
                                </> :
                                <img src='/images/menu.svg' alt=""/>}
                        </div>
                    </div>
                    <div className="logo"> Help Abode - Dashboard</div>
                    <CommonHeader/>
                </div>
            </header>
            <Container>
                {!show && <NavigationBar/>}
                <div className={!show ? 'wrapper selected' : 'wrapper'}>
                    <div className="header-section">
                        <span className="page-title">{page_title}</span>  
                        {/* <span className="page-title">{singleMeta?singleMeta.title:page_title}</span> ||  {title} ||  {pageId} */}
                        <BreadcrumbTextComponent breadcrumb={breadcrumbTree.length>0 ? breadcrumbTree:checkStructure(breadcrumb)}/>
                        {top_content && <div className="header-top-content">{top_content}</div>}
                    </div>
                    <div className="content-block">{component}</div>
                </div>
                <FooterComponent show={show}/>
                 <Firebase />
            </Container>

        </>
    );
};

export default Layout;
