
// export const BASE_URL = "http://admin.helpabode.local:54222";
// export const BASE_URL = "http://192.168.1.21:8000";
// export const BASE_URL = "http://192.168.1.8:5000";
export const BASE_URL = "https://admin.helpabode.com:54222";
// export const BASE_URL = "http://127.0.0.1:8000";
// export const BASE_URL = "http://192.168.1.13:8000";


 
 


// let token = localStorage.getItem("token")
//  console.log(token)
// // let config =  { }
//  if(token){
//   const config =  {
//     headers: {
//         Authorization: `Token ${token}`,
//         // "content-type": "multipart/form-data",
//         // 'Cache-Control': 'no-cache',
//         // 'Pragma': 'no-cache',
//         // 'Expires': '0',
//     }
// }

//  } 
//  export default config; 
