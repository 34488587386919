import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {fetchProfile, changeTeamMemberStatus } from '../../reducers/TeamMemberSlice'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link, useParams} from "react-router-dom";
import CustomModelComponent from "../../component/CustomModelComponent";
import CustomModal from "../../modal/CustomModal";
import SuccessModalComponent from "../../modal/SuccessModalComponent";
import HeaderComponent from "../../component/customer/HeaderComponent";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import GeneralInfo from "../../component/provider/GeneralInfo";
import UserOrders from "../../component/provider/ProviderOrders";
import CategoryRequested from "../../component/provider/CategoryRequested";
import BusinessInfo from "../../component/provider/BusinessInfo";
import SetProviderStatus from "../../component/provider/SetProviderStatus";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import {favouriteServiceListApi, fetchUserProfile, fetchUserWiseReviewRatingApi} from "../../reducers/CustomersSlice";
import order from "../../assets/images/order.svg";
import CustomerOrderComponent from "../../component/customer/CustomerOrderComponent";
import ReviewsRatingsPageComponent from "../../component/ratings/ReviewsRatingsPageComponent";
import UserGeneralInformation from "../../component/customer/UserGeneralInformation";
import CustomerSaveServiceComponent from "../../component/customer/CustomerSaveServiceComponent";
import ReviewRatingComponent from "../../component/customer/ReviewRatingComponent";
// import {item} from

const UserProfilePage = () => {
    const [activeTab, setActiveTab] = useState('profile')
    const [countRating, setCountRating] = useState('');
    const {profile, loading, error, reviewRatingData} = useSelector((state) => state.customers)
    // const {reviewRatingData} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    const {user} = useParams()
    const handleClick = (e) => setActiveTab(e);
    const handleNext = (e) => setActiveTab('category');
    const handleNextInfo = (e) => setActiveTab('info');

    const handleCountRating= (e) => setCountRating(e);


    useEffect(() => {
        let res = dispatch(fetchUserProfile(user))
        if (res) {
            dispatch(breadcrumbTree({"Customer List": 'customer', [user]: 'ddd'}));
            document.title = `Customer details ${user}`;
        }
 

    }, [dispatch])


    console.log(profile)

    return (
        <Layout page_title={`Customer ${user}`} breadcrumb={{'Customer': 'customer',[user]:''}} 
                component={
                    <>
                        {loading && <div>Loading ...</div>}
                        {/* {!profile && error ? <div>Error: {error}</div> : null} */}
                        {profile ?
                            <div className='provider customer bg-white p-3'>
                                {profile.address_data && <HeaderComponent profile={profile}
                                                             address={profile.address_data.length > 0 ? profile.address_data : false}/>}

                                <div className="w-[100%] flex-col justify-start items-start flex">
                                    <div className="justify-start items-start gap-7 inline-flex cursor-pointer">
                                        <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                            <div
                                                className={`justify-start items-center gap-2 inline-flex ${activeTab === 'profile' ? ' text-emerald-700' : ' text-gray-500'}`}
                                                onClick={() => handleClick('profile')}>

                                                <i className={`w-3.5 h-3.5 relative fa fa-list-ol`}
                                                   aria-hidden="true"></i>
                                                <div className="text-[15px] font-medium font-['Inter']">
                                                    Orders {profile.order_list && <>({profile.user_summary.totalOrders})</>}
                                                </div>
                                            </div>
                                            <div
                                                className={`self-stretch h-[3px] ${activeTab === 'profile' ? ' bg-emerald-700' : ' bg-white'}`}></div>
                                        </div>
                                        <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                            <div
                                                className={`justify-start items-center gap-2 inline-flex ${activeTab === 'review' ? ' text-emerald-700' : ' text-gray-500'}`}
                                                onClick={() => {
                                                    dispatch(fetchUserWiseReviewRatingApi(user))
                                                    return handleClick('review');
                                                }
                                                }>



                                                <i className={`w-3.5 h-3.5 relative fa fa-star`}
                                                   aria-hidden="true"></i>
                                                <div
                                                    className="text-[15px] font-medium font-['Inter']">
                                                    Reviews ({profile.total_review})
                                                </div>
                                            </div>
                                            <div
                                                className={`self-stretch h-[3px] ${activeTab === 'review' ? ' bg-emerald-700' : ' bg-white'}`}></div>
                                        </div>

                                        <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                            <div
                                                className={`justify-start items-center gap-2 inline-flex ${activeTab === 'savedServices' ? ' text-emerald-700' : ' text-gray-500'}`}
                                                onClick={() => {
                                                    dispatch(favouriteServiceListApi(user))
                                                    return handleClick('savedServices');
                                                }
                                                }>

                                                <i className={`w-3.5 h-3.5 relative fa fa-heart`}
                                                   aria-hidden="true"></i>
                                                <div
                                                    className="text-[15px] font-medium font-['Inter']">
                                                    Saved services
                                                </div>
                                            </div>
                                            <div
                                                className={`self-stretch h-[3px] ${activeTab === 'savedServices' ? ' bg-emerald-700' : ' bg-white'}`}></div>
                                        </div>
                                        <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                            <div
                                                className={`justify-start items-center gap-2 inline-flex ${activeTab === 'personalInformation' ? ' text-emerald-700' : ' text-gray-500'}`}
                                                onClick={() => handleClick('personalInformation')}>

                                                <i className={`w-3.5 h-3.5 relative fa fa-user-circle-o`}
                                                   aria-hidden="true"></i>
                                                <div
                                                    className="text-[15px] font-medium font-['Inter']">
                                                    Personal Information
                                                </div>
                                            </div>
                                            <div
                                                className={`self-stretch h-[3px] ${activeTab === 'personalInformation' ? ' bg-emerald-700' : ' bg-white'}`}></div>
                                        </div>


                                    </div>
                                    <div className="self-stretch h-[0px] border border-zinc-300 opacity-[50%]"></div>
                                </div>

                                <div className="container tabs-overview ">


                                    <div className="content-box w-[100%]">
                                        {activeTab === 'profile' &&
                                            <CustomerOrderComponent data={profile.order_list}/>}
                                        {activeTab === 'review' &&
                                            <>
                                                {/*Reviews Ratings List Under construction*/}
                                                <ReviewRatingComponent data={reviewRatingData.review_data}/>
                                                {/*{reviewRatingData && <ReviewsRatingsPageComponent data={reviewRatingData.review_data} filterBy={activeTab} getCountRating={handleCountRating}/>}*/}
                                            </>
                                        }




                                        {activeTab === 'savedServices' &&
                                            <>
                                                <CustomerSaveServiceComponent user={user}/>
                                            </>}
                                        {activeTab === 'personalInformation' &&
                                            <UserGeneralInformation profile={profile}/>}

                                    </div>
                                </div>


                            </div>
                            : null}
                        {loading && <LoadingComponent message={''}/>}
                        {error && <ErrorComponent error={error} redirectLink={'/EndUser'}/>}
                        {/*{error && <ErrorComponent close={handleSuccess}/>}*/}
                    </>
                }></Layout>
    );
}

export default UserProfilePage;