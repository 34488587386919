import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {
    fetchProfile,
    changeTeamMemberStatus,
    fetchCategoryServiceWithAreas,
    fetchTeamMembers,
    fetchWorkerDocumentsView, fetchProviderOrders
} from '../../reducers/TeamMemberSlice'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link, useParams, useLocation} from "react-router-dom";
import CustomModelComponent from "../../component/CustomModelComponent";
import CustomModal from "../../modal/CustomModal";
import SuccessModalComponent from "../../modal/SuccessModalComponent";
import HeaderComponent from "../../component/provider/HeaderComponent";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import GeneralInfo from "../../component/provider/GeneralInfo";
import ProviderOrders from "../../component/provider/ProviderOrders";
import CategoryRequested from "../../component/provider/CategoryRequested";
import BusinessInfo from "../../component/provider/BusinessInfo";
import SetProviderStatus from "../../component/provider/SetProviderStatus";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
// import {item} from
// import WorkerApproveComponent from '../../component/provider/WorkerApproveComponent';
// import WorkerApproveComponent from './component/WorkerApproveComponent';
const ProfilePage = () => {
    const [activeTab, setActiveTab] = useState('profile')
    const {profile, loading, error, images, order} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    const {item} = useParams()
    const handleClick = (e) => setActiveTab(e);
    const handleNext = (e) => setActiveTab('category');
    const handleNextInfo = (e) => setActiveTab('info');
    const { state } = useLocation();
 

    useEffect(() => {
        let res=dispatch(fetchProfile(item))
        if(res){
            dispatch(breadcrumbTree({"Provider List": 'provider', [state.name]: ''}));
            document.title = `Provider details: ${state.name} `; 
            dispatch(fetchWorkerDocumentsView(item))
            dispatch(fetchCategoryServiceWithAreas(item));
            dispatch(fetchProviderOrders('omarfaruk222375@gmail.com'));
        }
    },[item])

 
    return (
        <Layout page_title={'page Details'} breadcrumb={{'page Details':''}} 
                component={
                    <>
                        {loading && <div>Loading ...</div>}
                        {!profile && error ? <div>Error: {error}</div> : null}
                        {!loading ?
                            <div className='provider'>
                                <HeaderComponent profile={profile}/>

                                <div className="container tabs-overview mt-4">
                                    <div
                                        className="tab-section d-flex justify-content-between align-items-center w-100">


                                        <div className="tab-box">
                                            <button className={activeTab === 'profile' ? 'tab-btn active' : 'tab-btn'}
                                                    onClick={() => handleClick('profile')}>Profile
                                            </button>
                                            <button className={activeTab === 'info' ? 'tab-btn active' : 'tab-btn'}
                                                    onClick={() => handleClick('info')}>Business Info
                                            </button>
                                            {profile.employeeType!=="Under Provider" &&<button className={activeTab === 'category' ? 'tab-btn active' : 'tab-btn'}
                                                    onClick={() => handleClick('category')}> Services
                                            </button>}
                                            <button className={activeTab === 'orders' ? 'tab-btn active' : 'tab-btn'}
                                                    onClick={() => handleClick('orders')}>Orders
                                            </button>

                                        </div>
                                        {profile.status === 'Verified' || 
                                        profile.status === 'TemporaryBlock' || 
                                        profile.status === 'Declined' ||
                                        profile.status === 'Busy' ? 
                                        <SetProviderStatus profile={profile} loading={loading} error={error}/>:''}

                                    </div>
                                    <div className="content-box mt-4 mb-5 w-100">
                                        {activeTab === 'profile' && <GeneralInfo profile={profile} images={images} tabClick={handleNextInfo}/>}
                                        {activeTab === 'info' && <BusinessInfo profile={profile} images={images} tabClick={handleNext}/>}
                                        {activeTab === 'category' && <CategoryRequested profile={profile} activeTab={activeTab}/>}
                                        {activeTab === 'orders' && <ProviderOrders order={order}/>}
                                    </div>
                                </div>


                            </div>
                            : null}
                        {loading && <LoadingComponent message={''}/>}
                        {/*{error && <ErrorComponent close={handleSuccess}/>}*/}
                    </>
                }></Layout>
    );
}

export default ProfilePage;