import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

// import CustomDataTable from '../../components/CustomDataTable';
import {Button, Image} from 'react-bootstrap';
import CategoryAddForm from './CategoryAddForm';
import ServiceAddForm from '../service/ServiceAddForm';
// import FormBuilder from './FormBuilder';
import {BASE_URL} from '../../BaseUrl';
// import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
// import ServiceItemConfiguration from './ServiceItemConfiguration';
import {
    fetchServiceWithCategory,
    createCategory,
    updateCategory,
    deleteCategory,
    itemRankUpdateApi, successConfirmation, fetchActiveServiceItem
} from '../../reducers/CategorySlice';  
import { pageTitle } from '../../reducers/LocalDataSlice';
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import CardNavigationForItemComponent from "../../component/items/CardNavigationForItemComponent";
import CardViewForServiceComponent from "../../component/items/CardViewForServiceComponent";
// import * as PropTypes from "prop-types";
// import CardViewComponent from "../../component/items/CardViewComponent";
// import {checkStructure} from '../../utility/StringModify';


const AddCategoryPage = () => {
    const {loading, success, error, categories, serviceList,  configuration} = useSelector((state) => state.category)
     
    const [showService, setShowService] = useState(false);
    const [show, setShow] = useState(false);
    const [newItemAddStatus, setNewItemAddStatus] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const {item,category=item} = useParams();
    const [editId, setEditId] = useState('');
    const handleSuccess = () => dispatch(successConfirmation());
    const [title, setTitle] = useState(false);
    const handleClose = () => {
        setShow(false)
        setShowService(false)
    };
    let location = useLocation();
    const handleCategory = () => setShow(true);
    const handleService = () => setShowService(showService === false ? true : false);
    const handleConfig = (textId) => setNewItemAddStatus(true);
    const handleEdit = (id) => setEditId(id);

    useEffect(() => {
        
        let res= dispatch(fetchServiceWithCategory(`${item}/${category}`))
        res.then((results) => {
            
            dispatch(pageTitle({"name":'ff',"link":category}));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch, location])

 
 


    return (
        <Layout page_title={'Service Item'} 
            // breadcrumb={{'Service Item':'service', "":''}} 
            // breadcrumb={title?withCurrent:false}
            component={
            <>
            {!show && !showService && <div className='top-btn-area'>
                        {/*link: { item + category }*/}
                        {/* <legend>Categories Section:</legend> */}

                        {/* {configuration === false ?
                            <div><Link to={`/service/add-category/${item}/${category}`} className="new-configuration-btn add-new"> Add Category </Link></div>
                            
                            : 
                            <div className='pad-10'>
                                <div className='alert alert-warning'>Item configuration is not available. Please add
                                    configuration first
                                </div>
                                <div><Link to={`/service-items/${item}`} className='btn btn-warning'>Add Configuration </Link></div>
                            </div>} */}
 
                        {!loading && categories.length > 0 ? categories.map((each) => (
                            <CardNavigationForItemComponent item={each} viewUrl={`/service/${item}/${each.textId}`} editUrl={`/service/edit-category/${item}/${each.textId}`} />
                            )) : null}
                </div>}
                     
                {serviceList && <div className='top-btn-area'>
                    <div><Link to={`/service/add-service/${item}/${category}`} className="new-configuration-btn add-new">Add New Service</Link></div> 

                {serviceList.length > 0 && serviceList.map((each, index) => (<CardViewForServiceComponent each={each} viewUrl={`/service/view/${item}/${each.serviceTextId}#view`} editUrl={`/service/edit-service/${item}/${each.serviceTextId}`} />))}
                </div>}


                {showService && <ServiceAddForm  serviceItemTextId={item} close={handleClose} newItemAddStatus={handleConfig}/>}

                {loading && <LoadingComponent message={''}/>}
                {error && <ErrorComponent close={handleSuccess}/>}
            </>
            }>
                
        </Layout>
 
    )
}

export default AddCategoryPage;