import { useEffect, useState } from "react";
import {Button, Form} from 'react-bootstrap';
import AddMetaForPageComponent from "./component/AddMetaForPageComponent";
import { useDispatch, useSelector } from "react-redux";
 
import {fetchPageMetaSettings, updatePageMetaSettings} from "../../reducers/DashboardSlice";
// import {Switch} from "../../../src/component/form-element/Switch";

// import doneAll from "../../assets/images/done-all.svg";
 
// import {fetchRolePermissionList, updateRolePermissionColumns, deleteRolePermission} from "../../reducers/IdentitySlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";

import ExportFile from "../../component/utility-component/ExportFile";
import { toast } from 'react-toastify';
import '../../assets/tostify/ReactToastify.css';
import {filterDataTable2Col} from "../../utility/FilterDataTable";
const PageSetting = () => {
  const [show, setShow] = useState(false);
 
  const [query, setQuery] = useState('');
  // const [ uniqueData, setUniqueData] = useState([]);
  // const [ pageData, setPageData] = useState(metaData?metaData:[]);
  const [filter, setFilter] = useState('');
  const [alertSuccess, setAlertSuccess] = useState('');
 
  const handleShow = () => setShow(true);
  const handleAlert = () => {setAlertSuccess(false)};
  // const handleApi = (item) => {
  //   const res= dispatch(updatePageMetaSettings(item));
  //           res.then(results => {
  //               if(results.error) {toast(` Something went wrong. Please try again!`) }
  //           }).catch(error => {
  //               alert('error ---- false')
  //           });

  // };

 

  const {metaData} = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  // let tableData = null;

  console.log(metaData)
 

 
  const handleClose = (data) =>{
    console.log(data)
    if(data) {
    if(metaData.update || metaData.id){
       toast(` Your action was successful! `);
      }  
    }
    
    setShow(false); 
    setInitialData({...initialData,
      update: false,
      title: null,
      pageUrl: null,
      breadcrumbTree: null
 
    })
  }
  const [ initialData, setInitialData ] = useState({
      update: false,
      title: null,
      pageUrl: null,
      breadcrumbTree: null
    })
    let uniqueData =[]
   
     if (metaData.length > 0) {
        const viewTableData = metaData.filter(item => item['title']?.toLowerCase().includes(query.toLowerCase()))
        let tableData1 = metaData.filter(item => item['pageUrl']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData2 = viewTableData.filter(item => item['orderTextId']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData3 = viewTableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
        uniqueData = filterDataTable2Col('id', viewTableData, tableData1);
        // uniqueData=viewTableData;
    }

   
  // toast.success("Login success", results); 
    useEffect(() => {
      // dispatch(fetchNotificationTemplateData());
      dispatch(fetchPageMetaSettings());
      // dispatch(fetchPageMetaInfo('/service-items'));
      dispatch(breadcrumbTree({'Setting': 'setting','Worker Role Permission':''}));
      // document.title = 'HelpAbode Notification Template Create by Admin';
      
    }, []);

    
  const columns = [
    // { name: "ID", sortable: true, cell: (row) => row.id },
    { name: "Title", sortable: true,  minWidth: '25%', className: 'custom-class-for-name-column', cell: (row) => row.title },
    { name: "Page URL", sortable: true, cell: (row) => row.pageUrl },
    { name: "Breadcrumb Tree", minWidth: '100px', sortable: true, cell: (row) => row.breadcrumbTree},
     
    {name: "Action", width: '100px', cell: (row) => <button className="update-btn btn" onClick={() => {
          let localData = null;
           metaData.map((e, index)=>e.id === row.id? localData = index:'');
            setInitialData((initialData) => ({
            ...initialData,
            update: true,
            id: row.id, 
            title: row.title,
            pageUrl: row.pageUrl,
            breadcrumbTree: row.breadcrumbTree,
            localData: localData
          }))
          setShow(true)
        }}> <i className="fa fa-pencil" aria-hidden="true"></i> Edit</button>},
 
  ];
   
  return (
    <Layout page_title={'Page/Meta setting'} breadcrumb={{'Setting': 'setting','Worker Role Permission':''}}
      component={
        <> 
            {show ? <AddMetaForPageComponent data={initialData} close={handleClose}  />:
            <div className='data-table-wrapper newNotification-table'>
              {alertSuccess &&<div className="alert alert-success">
                <strong>Success!</strong>  
              </div>}
              {/* <ToastContainer className="ToastContainer"/> */}
               <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                {/* <Button variant="primary" onClick={handleShow}> Add Attribute </Button> */}
              <Button className="new-configuration-btn add-new" onClick={handleShow}> Add Item </Button>

              <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px] relative'>
                <input name="search" placeholder={'Search by  Title...'} type="text"
                className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                value={query ? query : ''}
                onChange={(e) => {
                  setQuery(e.target.value)
                }}/>
                <div className={"absolute left-[12px] top-[12px]"}>
                  <i className="fa fa-search" aria-hidden="true"></i>
                </div>
              </div>
              <div className="export-btn absolute right-0 top">
                <ExportFile data={uniqueData} fileName={'FAQ-template-data'}/>
              </div>
            </div>
                            

                             
              
              <CustomDataTable data={uniqueData} columns={columns} />
          </div>}

            
 
          
        </>
      }
    ></Layout>
  );
};

export default PageSetting;
