import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {fetchProviderTipManagementApi} from '../../reducers/TeamMemberSlice'
import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link} from "react-router-dom";
import {fetchActiveServiceItem} from "../../reducers/CategorySlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import DatePicker from "react-datepicker";
import * as moment from 'moment';
import CustomDatePickerInput from '../../component/CustomDatePickerInput';
import TipsRewardsDataTable from "../../dataTables/TipsRewardsDataTable";
import {filterDataTable5Col} from "../../utility/FilterDataTable";
import ExportFile from "../../component/utility-component/ExportFile";
import NotDataFound from "../../components/NotDataFound";
// import Button from 'react-bootstrap/Button';
import LoadingComponent from "../../modal/LoadingComponent";  

const TipsAndRewardsPage = () => {
    const [startDate, setStartDate] = useState(new Date());
    const {tips, loading, error} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [searchType, setSearchType] = useState('userTextId');
    const [query, setQuery] = useState('');

    const [downloadLink, setDownloadLink] = useState(null);
    const data = 'This is the content of the downloaded file.';
    const blob = new Blob([data], {type: 'text/plain;charset=utf-8'});

    console.log('tips::',tips)
    let viewTableData = tips
    let uniqueData = [];

    if (tips) {
        viewTableData = tips.filter(item => item['serviceTitle']?.toLowerCase().includes(query.toLowerCase()))
        let tableData1 = tips.filter(item => item['providerName']?.toLowerCase().includes(query.toLowerCase()))
        let tableData2 = tips.filter(item => item['providerTextId']?.toLowerCase().includes(query.toLowerCase()))
        let tableData3 = tips.filter(item => item['userName']?.toLowerCase().includes(query.toLowerCase()))
        let tableData4 = tips.filter(item => item['userTextId']?.toLowerCase().includes(query.toLowerCase()))
        uniqueData = filterDataTable5Col('orderTextId', viewTableData, tableData1, tableData2, tableData3, tableData4);

    }

    const columns = [
        
        {name: "Service Title", sortable: true, selector: (row) => row.serviceTitle},
        {name: "Order ID", sortable: true, selector: (row) => row.orderTextId},
        {name: "Order Date", minWidth: '200px', sortable: true, selector: (row) => moment(new Date(row.orderDate)).format("DD MMM YYYY,  h:mm A ")},
        {name: "Amount", sortable: true, selector: (row) => row.orderAmount},
        {
            name: "Customer",
            minWidth: '150px',
            sortable: true,
            selector: (row) => <div><strong>{row.userName}</strong> </div>
        },
        {name: "Tips Amount", sortable: true, selector: (row) => row.tipAmount},
        {
            name: "Provider",
            minWidth: '250px',
            sortable: true,
            selector: (row) => <div><strong>{row.providerName} </strong><br/>{row.providerTextId}</div>
        },
        {name: "Tips Date", minWidth: '200px', sortable: true, selector: (row) => row.tipDate},
    ]

    useEffect(() => {
        let res = dispatch(fetchProviderTipManagementApi())
        res.then((results) => {
            dispatch(breadcrumbTree({'Tips and Rewards': ''}));
            document.title = 'Tips and Rewards';
            // dispatch(breadcrumbTree([{'Service ': 'service-area'},results.payload.pageBreadcrumb]));
            // dispatch(breadcrumbTree({'Service ':'service-area', 'nur':'dddd'}));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch])


    return (
        <Layout page_title={'Tips and Rewards'} breadcrumb={{'Tips and Rewards':''}} 
        component={
            <>
            {loading && <LoadingComponent message={''}/>}
            {/* {error && <ErrorComponent close={handleSuccess}/>} */}
              
                {!loading ?
                    <div className='data-table-wrapper tips-rewards'>


                        <div className="w-[100%] flex gap-2.5 relative mb-3">
                            {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                            <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                                <input name="search" placeholder={'Search by Services Title, Provider or customer...'}
                                       type="text"
                                       className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                       value={query ? query : ''}
                                       onChange={(e) => {
                                           setQuery(e.target.value)
                                       }}/>
                                <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                                      aria-hidden="true"></i>
                                </div>
                                <div
                                    className="export-btn absolute right-0 top">

                                    <ExportFile data={uniqueData} fileName={'Tips-and-Rewards'}/>
                                </div>
                            </div>
                        </div>

                        <TipsRewardsDataTable data={uniqueData} columns={columns} />
                    
                    </div>
                    : <NotDataFound />}
            </>
        } 
        top_content={
            <>

                
            </>
        }></Layout>
    )
}

export default TipsAndRewardsPage