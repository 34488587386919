
//import './styles.css';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form';
import {createUser} from '../../reducers/AuthSlice';
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/Layout";
import {useForm} from 'react-hook-form';
import SideBarData from "../../data/leftSideBar";
import {NavLink} from "react-router-dom";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import {fetchDashboard} from "../../reducers/DashboardSlice";
// import OrderManagementComponent from "./component/OrderManagementComponent";
// import ServiceCardComponent from "./component/ServiceCardComponent";
// import ProviderListComponent from "./component/ProviderListComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import UnderConstructionComponent from '../../components/UnderConstructionComponent';

const Setting = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const { loading, dashboardData, error, success, cardList, this_month_order, last_month_order, provider_list} = useSelector((state) => state.dashboard)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchDashboard());

        dispatch(breadcrumbTree({'Setting': ''}));
        document.title = 'HelpAbode Admin Setting';
    }, [dispatch]);

    return (

        <Layout page_title={'HelpAbode Admin Setting'} breadcrumb={{'Setting':''}}
                component={
                    <div className='dashboard-page'>
                        {/* {cardList && <div className="overview-cards">
                            <div className="row">
                                {cardList.map((item, index) => (
                                    <ServiceCardComponent data={item}/>
                                ))}

                                <h4>Have to plan the setting module again!</h4>
                            </div>
                        </div>}
 

                        {provider_list && <div className="provider_list">
                            <div className="row">
                                <ProviderListComponent data={provider_list}/>
                            </div>
                        </div>} */}
                        <UnderConstructionComponent />
                        {loading && <LoadingComponent message={''}/>}
                    </div>

                }
        ></Layout>
    );
}
  
export default Setting; 
