//import './styles.css';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form';
 
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/Layout";
import UnderConstructionComponent from "../../components/UnderConstructionComponent";
import {useForm} from 'react-hook-form';
import SideBarData from "../../data/leftSideBar";
import {NavLink} from "react-router-dom";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
 
  
import LoadingComponent from "../../modal/LoadingComponent"; 

const AdminHome = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const { loading, dashboardData, error, success, cardList, this_month_order, last_month_order, provider_list} = useSelector((state) => state.dashboard)
    const dispatch = useDispatch();


    useEffect(() => {
       

        dispatch(breadcrumbTree({'Profile': ''}));
        document.title = 'HelpAbode Admin Dashboard';
    }, [dispatch]);

    return (

        <Layout page_title={'Admin Profile'} breadcrumb={[{'Under Construction!':'',"link":'profile'}]} 
                component={
                    <div className='dashboard-page'>
                        
                        <UnderConstructionComponent />
                         
                        
                    </div>

                }
        ></Layout>
    );
}

export default AdminHome;
// what is redux toolkits?
// how to create functional components?
