import {Form, Modal, Button, Image} from "react-bootstrap";
import Layout from '../../components/Layout';
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useFormik} from "formik";
import {breadcrumbTree} from '../../reducers/LocalDataSlice'
import {fetchServices,  fetchServiceDetails, createService} from "../../reducers/ServiceItemSlice";
import {appendNewServices, fetchServiceWithCategory, successConfirmation} from '../../reducers/CategorySlice';
import ServicePlanAddComponent from "../../component/ServicePlanAddComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import SelectServiceItemComponent from "../../component/SelectServiceItemComponent";
import ServiceZoneComponent from "./component/ServiceZoneComponent";



const ViewServicePage = () => {
    const { error, categories, serviceList, configuration, title} = useSelector((state) => state.category)
    const {configuration_data, loading, success, serviceDetails} = useSelector((state) => state.serviceItem);
    const [breadcrumb, setBreadcrumb] = useState(false);
    const { state, location } = useLocation();
    const [activePlan, setActivePlan] = useState();
    const [selected, setSelected] = useState();
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState('');
    const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);

    const {service_data, itemArray, plan_list, serviceZipArray, categoryArray}=serviceDetails


    //  {serviceDetails && setSelected(serviceDetails.service_data.areaSizeUnit)}

    // let location = useLocation();
    // console.log('serviceDetails', service_data, itemArray, serviceplanArray)

   
    // const loca = location.pathname.split("/");
    // const token = localStorage.getItem("token")
    const {item, service = 'root'} = useParams();
    const navigate = useNavigate();
    const [clickCount, setClickCount] = useState(1);

    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
    console.log('item:', item)
    // console.log('service:', service)

    

 

      
    // console.log("🚀 values:", values)
// console.log(serviceDetails)

    useEffect(() => {
        // dispatch(fetchServices(state.categoryTextId))
        const res = dispatch(fetchServiceDetails(service))
        document.title = 'Service Details';
        // dispatch(breadcrumbTree({
        //     'Service ': 'service',
        //     // [title]: subItem
        // }));
        res.then((results) => {
          console.log('results: ',results)
        })
      res.catch((error) => {
          console.error('Promise rejected:', error);
      });

    }, [dispatch])
    // console.table(configuration_data.service_plan)
    // console.table(serviceDetails.itemArray)

    return (
        <Layout component={
            <>
                {serviceDetails&&<div className="form-data">
                    <div className="section-two-column add-service">
                        <div className="left-block">
                            <div className="field-row">
                                <label className="label">Service Name</label>
                                <div className="form-control">{service_data && service_data.title}</div>
                            </div>

                            <div className="field-row">
                                <label className="label">Item Description</label>
                                <div className="form-control description">{service_data &&  service_data.shortDescription}</div>
                                 
                            </div>

                            <div className="field-row">
                                <label className="label">Pricing By</label>
                                <div className="form-control description">Pricing By: {service_data && service_data.pricingBy} {service_data && service_data.areaSizeUnit && ':'+service_data.areaSizeUnit}</div>
                            </div>
                            <div className="dragged-form-item">

                                <div className="form-item">
                                    <div className="input-area">
                                        <div className="add-new-level">
                                            <strong className="field-label">Service Plan</strong>
                                        </div>

                                        <div className="level bronze">

                                            {/* service_data, itemArray, serviceplanArray */}
                                       
                                            {plan_list && plan_list.map((item) => (
                                                <div className="field-row">
                                                    <span className="label"> Plan Title: {item.planTitle}</span>
                                                    {/* <div className="form-control">{item.planTitle}</div> */}
                                                    {/* <br /> */}
                                                    <span className="label"> Description:</span>
                                                    <div className="form-control">{item.servicePlanDescription}</div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>

                            </div>
                             {serviceZipArray && <ServiceZoneComponent data={serviceZipArray} />}
                            
                        </div>
                        <div className="right-block">
                            {itemArray.length>0 &&<div className="items-category">
                                <div className='title'>Selected Service Items</div>
                                <div className="form-item category-items"> 
                                    {/* {console.log('itemArray:',itemArray)} */}

                                    <ul className="list-style">
                                        {itemArray.map((item) => <li><strong>{item.itemName} </strong></li>)} 
                                     </ul>
                                </div>
                            </div>}
                            

                            {/* {categoryArray.length>0 &&<div className="items-category">
                                <div className='title'>Selected Service Items</div>
                                <div className="form-item category-items"> 
                                    <ul className="list-style">
                                        {categoryArray.map((ca) => <li><Link to={`/service/${item}/${ca.categoryTextId}`}>{ca.title} </Link></li>)} 
                                    </ul>
                                </div>
                            </div>} */}


                            <div className="category">
                                <strong className="title">Service Item Image</strong> 
                                <div className="img-view" style={{backgroundImage: `url(${BASE_URL}${service_data && service_data.image})`}} ></div>
                                </div>
                       
                        </div> 

                    </div>


                        <div className="save-option">
                            {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft</button> */}
                            <button className="save-btn" onClick={()=>navigate(-1)} >Back to List</button>
                        </div>

                    </div>}
               
                {loading && <LoadingComponent message={''}/>}
                {error && <ErrorComponent close={handleSuccess}/>}
                {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>}></Layout>

    );
};

export default ViewServicePage;
