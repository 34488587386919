import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate} from "react-router-dom";

import {Button, Image} from 'react-bootstrap';
import ServiceItemAddForm from './ServiceItemAddForm';
import {BASE_URL} from '../../BaseUrl';
import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
import ServiceItemConfiguration from './ServiceItemConfiguration';
import {
fetchServiceSubItem,
    fetchServiceItem,
 
} from '../../reducers/CategorySlice';
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import ServiceSubItemForm from "./ServiceSubItemForm";
// import {  itemRankUpdateApi, fetchServiceItem } from '../../reducers/FormBuilderSlice';  
const token = localStorage.getItem("token");

const ServiceItemList = () => {
    const { title, identityArr, itemImage, itemIcon} = useSelector((state) => state.category)
    
    const navigate = useNavigate();
    // let location = useLocation();
    const dispatch = useDispatch()
    const handleClose = () => { navigate(-1)};
    const textId = window.location.hash.replace('#', '');

    // const parrentData= items.filter(item => item['textId']===textId&&{image:item.image, icon:item.icon})
 
    // console.log('----------------:',parrentData)
//  console.log(itemImage, itemIcon)

    useEffect(() => {
        if(textId){
            dispatch(fetchServiceSubItem(textId));
        }
    //    dispatch(fetchServiceSubItem());
    //    dispatch(fetchServiceItem());
    }, [dispatch])
    const itemInitialData = {
        title: '',
        rank: false,
        status: false,
        details: '',
        image: false,
        isRegistrationProcess: false,
        icon: false,
        isBundleItem:false,
        isSkippable:'N',
        isShowInAppTop:'N',
        // isHouse:'N',
        identifierTextId: false
    };
  const subInitialData = {
        parentTextId: textId,
        title: '',
        rank: false,
        status: false,
        details: '',
        image: false,
        icon: false
  }

    return (
        <>
            <Layout page_title={'Create a New Service Item'}  
             component={
                <>
                    {window.location.hash ? <ServiceSubItemForm data={{...subInitialData,image: itemImage,icon: itemIcon}} /> :
                        <ServiceItemAddForm data={itemInitialData} identityArr={identityArr}/>}

                </>
            }></Layout>
        </>
    )
}

export default ServiceItemList;