import {Form, Modal, Button, Image} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";

import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {itemFormSchema} from "../../schemas/item";
import {useFormik} from "formik";
import {
    updateServiceItem,
    createServiceItem,
    successConfirmation,
    setEditedData,
    fetchServiceItem
} from '../../reducers/CategorySlice'
import {useNavigate, useParams} from "react-router-dom";
import LoadingComponent from "../../modal/LoadingComponent";
import SuccessModalComponent from "../../modal/SuccessModalComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import {fetchConfiguration, setConfigurationData} from "../../reducers/FormBuilderSlice";
// import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import {createPageMetaSettings} from "../../reducers/DashboardSlice";
import {checkStructure} from '../../utility/StringModify'
const ServiceItemAddForm = ({data, identityArr}) => {
    // const [loading, setLoading] = useState(false);
    const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
    // const [imageError, setImageError] = useState(false);
    const [itemAddStatus, setItemAddStatus] = useState(false);
    const {loading, error} = useSelector((state) => state.category)
    const { singleMeta, breadcrumbTree } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    const {item} = useParams();
    // const handleLoading = () => setLoading(loading ? false : true);
    // const handleClose = () => setClose(close ? false : true);
    // console.log(data)

    const handleSuccess = ()=>dispatch(successConfirmation());

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
                // setValues({...values, thumbImage: e.target.result});
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    const handleIconChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setIcon(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
    // console.log('identityArr:',identityArr)
    // data.index=index;
// if(data.isRegistrationProcess==="N") setIsChecked(true)

       
//  console.log('brTree 111: ', checkStructure(breadcrumbTree).concat([{"name":'values.title',"link":'/fff'}]))
    const {values, errors, formik, touched, handleBlur, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: data,


        onSubmit: (values, action) => {

          

            if(data.textId){
                const res = dispatch(updateServiceItem(values));
                res.then((results)=> {
                    // dispatch(fetchServiceItem())
                    openSuccessModal();
                    navigate(-1)

                }).catch(error => {
                    console.error('Error:', error);
                });
       
            }else{
            const res = dispatch(createServiceItem(values));
            res.then((results)=> {
                if(!results.error){
                dispatch(setConfigurationData([]))

                console.log("Service:", breadcrumbTree)

                const brTree= checkStructure(breadcrumbTree)
                const withCurrent= brTree.concat([{"name":values.title,"link":results.payload.textId}])
                dispatch(createPageMetaSettings({"title":values.title, "breadcrumbTree":JSON.stringify(withCurrent),"pageUrl":`/service-items/${results.payload.textId}`}))
                dispatch(createPageMetaSettings({"title":values.title, "breadcrumbTree":JSON.stringify([{"link": "service", "name": "Item Listing"},{"name":values.title,"link":results.payload.textId}]),"pageUrl":`/service/${results.payload.textId}`}))
                
                openSuccessModal(`/service-items/${results.payload.textId}/configuration`);
                }

            }).catch(error => {
                console.error('Error:', error);
            });
            }
            // close(true);
        },
         validationSchema: itemFormSchema,


    });
    //   console.table('after',values)
    console.table('values',values)
    // console.table(' {identityArr.lenght}',identityArr.length)
    useEffect(() => {
        setSuccessModal(false)
        // dispatch(breadcrumbTree({'Service Item List': 'service-items', 'Add New Item': 'add-item'}));
        document.title = 'Service New Item';
    }, [dispatch])
    return (
        <>
            <Form onSubmit={handleSubmit} encType="multipart/form-data" className="form-data">
                <div className="section-two-column add-service">
                    <div className="left-block w100-200">
                        

                        <div className="field-row"><label className="label">Service Item Name</label>
                            <Form.Control type="text" name="title" placeholder="Service Item Name" value={values.title}
                                          onChange={handleChange} onBlur={handleBlur}></Form.Control>
                            {errors.title && <p className="red">{errors.title}</p>}
                            {/* <Form.Control type="hidden" name="categoryTextId" value={data.categoryTextId} onChange={handleChange} onBlur={handleBlur}></Form.Control> */}
                            </div>

                            <div className="field-row"><label className="label">Item Description</label>
                            <textarea className="form-control description" name="details" rows="5" placeholder="Description"
                                      value={values.details} onChange={handleChange}></textarea>
                            {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea} />*/}
                             {errors.details && <p className="red">{errors.details}</p>}
                            </div>
{/*                             
                            {identityArr && identityArr.length>0 &&<div className="field-row">
                            <label className="label">Item Identifier </label>
                            <select name={`identifierTextId`} className="form-select" 
                            onChange={(e)=>{
                                 setValues({...values, identifierTextId: e.target.value})
                               return handleChange}}>
                            <option key='identityArr' value='' selected >Select Option</option>
                            {identityArr.map((option, r) => (  
                                <>{option.status==='N'&&<option key={`${option.textId}_${r}`} value={option.textId}>{option.title}</option>}</>
                                ))}
                            </select>
                            
                        </div>} */}

                        <div className="field-row check-box">
                            <Form.Check type="switch" id="isRegistrationProcess"
                                label="Include in registration process" name="isRegistrationProcess"
                                    defaultChecked={values.isRegistrationProcess === 'Y'? true:false}  
                                onClick={(e)=>{
                                    if(e.target.checked){
                                        setValues({...values, isRegistrationProcess: 'Y'})
                                    } else {
                                        setValues({...values, isRegistrationProcess: 'N'})
                                    }
                                    return handleChange
                                }}/>
                                
                                <Form.Check type="switch" id="isSkippable"
                                    label="Is requird on registration process" name="isSkippable"
                                        defaultChecked={values.isSkippable === 'Y'? true:false}
                                    onClick={(e)=>{
                                        if(e.target.checked){
                                            setValues({...values, isSkippable: 'Y'})
                                        } else {
                                            setValues({...values, isSkippable: 'N'})
                                        }
                                        return handleChange
                                    }}/>

                                <Form.Check type="switch" id="isShowInAppTop"
                                    label="Is show in EndUser app top" name="isShowInAppTop"
                                        defaultChecked={values.isShowInAppTop === 'Y'? true:false}
                                    onClick={(e)=>{
                                        if(e.target.checked){
                                            setValues({...values, isShowInAppTop: 'Y'})
                                        } else {
                                            setValues({...values, isShowInAppTop: 'N'})
                                        }
                                        return handleChange
                                    }}/>

                                    {/* <Form.Check type="switch" id="isHouse"
                                    label="Is House?" name="isHouse"
                                        defaultChecked={values.isHouse === 'Y'? true:false}
                                    onClick={(e)=>{
                                        if(e.target.checked){
                                            setValues({...values, isHouse: 'Y'})
                                        } else {
                                            setValues({...values, isHouse: 'N'})
                                        }
                                        return handleChange
                                    }}/> */}
                                    {/*<span className="label-name">Include in registration process</span></label>*/}
{/* 
                                {values.image} 
                                <br />
                                {values.icon} 
                                {thumbIcon} 
                                {}  */}

                                    
                        </div>
                    </div>
                    <div className="right-block w100-200">
                           
                        <div className={`category`}>
                            <strong className="title">Service Item Image</strong>
                            {!thumbImage && !values.image &&<span className="info">Add image for the service item</span>}
                            
                            <div className={`img-view ${errors.image && 'border-red'}`} style={{backgroundImage: `url(${thumbImage?thumbImage:(`${BASE_URL}${values.image}`)})`}}> 
                            {/* <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:values.image?(`${BASE_URL}/${values.image}`): uploadImgIcon})`}}>  */}
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleImageChange(e);
                                    setValues({...values, image: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label>
                           

                                
                                        {!thumbImage && !values.image && <><div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                        <span className="drag-drop">Drop image here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span></>}
                                    {/* {errors.image && <p className="red">{errors.image}</p>}  */}
                            </div>
                        </div>

                        <div className={`category`}>
                            <strong className="title">Service Item Icon</strong>
                            {!thumbIcon && !values.icon &&<span className="info">Add icon for the service item</span>}
                            
                            <div className={`img-view ${errors.icon && 'border-red'}`} style={{backgroundImage: `url(${thumbIcon?thumbIcon:(`${BASE_URL}${values.icon}`)})`}}> 
                            {/* <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:values.image?(`${BASE_URL}/${values.image}`): uploadImgIcon})`}}>  */}
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="icon" onChange={(e) => {
                                    handleIconChange(e);
                                    setValues({...values, icon: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label>

                                
                                        {!thumbIcon && !values.icon && <><div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                        <span className="drag-drop">Drop icon here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span></>}
                                    {/* {errors.image && <p className="red">{errors.image}</p>}  */}
                            </div>
                             
                              
                       
                        </div>

                        {/* <div className="category">
                            <strong className="title">Service Item Icon</strong>
                            <span className="info">Add Icon for the service item</span>
                            <label id="drop-area">
                                
                                <div className="field-row">
                                    
                                    <input type="file" hidden name="image" onChange={(e) => {
                                        handleIconChange(e);
                                        setValues({...values, icon: e.target.files[0],});
                                        return handleChange
                                    }} accept="image/*"/>
                                    
                                    <div className="canvas"><img src={thumbIcon ? thumbIcon : uploadImgIcon} alt=""/>
                                     
                                    </div>
                                    <span className="drag-drop">Drop image here, or browse</span>
                                    <span className="support-image">Support: JPG, JPEG, PNG</span>
                                </div>
                            </label>
                        </div> */}
                    </div>


                    <div className="save-option">
                        <button onClick={(e)=>{ e.preventDefault(); navigate(-1)}}> <span className="draft-icon"></span> Cancel</button>
                        <button className="save-btn">Save</button>
                    </div>

                </div>
            </Form>

            {loading && <LoadingComponent message={''}/>}
            {error && <ErrorComponent close={handleSuccess}/>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}

        </>
    );
};

export default ServiceItemAddForm;
