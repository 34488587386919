import React, {useState} from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link, useNavigate, useLocation} from "react-router-dom";
 
import {BASE_URL} from "../../BaseUrl";
import {itemRankUpdateApi} from "../../reducers/CategorySlice";
import {useDispatch} from "react-redux";
import SuccessComponent from "../../modal/SuccessComponent";
import {getStatusName} from '../../utility/GetStatusName';

const CardViewComponent = ({item, path, navTree}) => {
     const navigate = useNavigate();
    return (
        <div className="card">
            <span className='link' onClick={() => {
                            navigate(path, {
                            state: {
                                title: item.title,  
                                textId: item.textId,
                                navTree,  
                            },
                            });
                        }}>
                            <img src={item.image && `${BASE_URL}${item.image}`} className='icon' alt=''/> 
                            {item.title}
                 
            </span>
            <hr/>
           
            <div className={`status-text ${item.status}`}>{getStatusName(item.status)}</div>
            <input type='number' className='qty' defaultValue={item.rank} disabled/>
        </div>

    );
};

export default CardViewComponent;
