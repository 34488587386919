import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
// import {createService, fetchServices} from "./ServiceItemSlice";

const initialState = {
    loading: false,
    items: [],
    services: [],
    success: false,
    error: '',
    ref:false,
}

// const url = `${BASE_URL}/category/`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
        // 'Accept': 'application/json'
    }
}

export const createServicenur = createAsyncThunk(
    "settings/add", async (formData) => {
        const cUrl = `${BASE_URL}/service/${formData.categoryTextId}/CategoryService`;
        return axios
            .post(cUrl, formData, config)
            .then((response) => response.data)
    }
)

export const serviceImageUpdate = createAsyncThunk(
    "settings/add", async (formData) => {
        const config1 = {
            headers: {
                Authorization: `Token ${token}`,
                "content-type": "multipart/form-data"
            }
        }
        const cUrl = `${BASE_URL}/service/${formData.categoryTextId}/CategoryService`;
        return axios
            .post(cUrl, formData, config1)
            .then((response) => response.data)
    }
)


const nurSlice = createSlice({
    name: "category",
    initialState,

    extraReducers: (builder) => {


        builder.addCase(createServicenur.pending, (state) => {
            // state.loading = true;
            state.success= false;
        });
        builder.addCase(createServicenur.fulfilled, (state, action) => {
            // state.services = action.payload;
            state.services=action.payload;

            state.success= true
            state.loading = false;
        });
        builder.addCase(createServicenur.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success= false;
            // state.services = [];
        });
    },
});



export default nurSlice.reducer
