import Breadcrumb from 'react-bootstrap/Breadcrumb';
import React, {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

function BreadcrumbExample({breadcrumb}) {
    const [link, setLink] = useState('/');
    const navigate = useNavigate();
    const handleClick = (e,path) => {
        e.preventdefault();
        if (path) { // Prevent navigation for active items (already on that page)
            navigate(path); // Use history.push from react-router-dom for navigation
        }
    };

    return (
        <Breadcrumb>
            <React.Fragment key={'root'}>
                <Breadcrumb.Item href='/dashboard' onClick={(e) => handleClick(e,'/')}>Dashboard </Breadcrumb.Item>
            </React.Fragment>
            {/* {Object.keys(breadcrumb).map((item, index) => (
                <React.Fragment key={item}>

                    {breadcrumb[item] ? (
                        <Breadcrumb.Item href={breadcrumb[item]} onClick={(e) => handleClick(e,breadcrumb[item])}>{item} </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item active>{item}</Breadcrumb.Item>
                    )}
                </React.Fragment>
            ))} */}
            {/* {console.log('in breadcrumb', breadcrumb)} */}
             {breadcrumb.length>0 && breadcrumb.map((item, index) => (
                 <React.Fragment key={item}>                       
                    {item.link!=='' ? (
                        <Breadcrumb.Item href={index===0?'/'+item.link:item.link} onClick={(e) => handleClick(e,item.link)}>{item.name} </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item active>{item.name} </Breadcrumb.Item>
                    )}
                </React.Fragment>
            ))}
        </Breadcrumb>

    );
}

export default BreadcrumbExample;