import React, {useState} from 'react';
import OptionAddAndFromSave from './OptionAddAndFromSave';
import DroppableComponentInner from './DroppableComponentInner';
import {useDispatch, useSelector} from 'react-redux';
import Form from 'react-bootstrap/Form';
// import UnGroupServiceComponent from './component/UnGroupServiceComponent'
import {updateValue, removeAttribute, updateLabel, dropElement, updateMeasurement} from '../../reducers/FormBuilderSlice';
import UnGroupServiceComponent from "./component/UnGroupServiceComponent";
import ConfigurationPriceComponent from "./component/ConfigurationPriceComponent";
// import 'react-tooltip/dist/react-tooltip.css'
import { measurementArr } from '../../utility/Measurement';
 
// import { Tooltip as ReactTooltip } from 'react-tooltip'
 import attributeSlice from './../../reducers/AttributeSlice'; 

const ItemComponent = ({dataItem, index, parent, rank, totalItem}) => {
    const [show, setShow] = useState(false);
    const dispatch = useDispatch()
    const [active, setActive] = useState(dataItem.measurement?true:false); 
    // const [selected, setSelected] = useState(data.optionalSizeUnit?data.optionalSizeUnit:'sqft');
      const handelActive=()=>setActive(!active);
    const handleShow = () => {
        setShow(show === false);
    };
    const handleChange = (column, val) => {
        
        dispatch(updateValue([index, column, val, parent]))
    };
    const handleRankUp = () => {
       console.log('dd',dataItem.rank + 1)
        // rank(parent, index)
        const curr={...dataItem, rank: dataItem.rank + 1}
        console.log('af',curr)
    };
    const handleRankDown = () => {
       console.log(dataItem.rank - 1)
        // rank(parent, index)
    };
    const handleLabelName = (column, val) => {
        console.log('handleChange: ', val);
        dispatch(updateLabel([index, column, val, parent]))
    };
        const handeleMeasurement = (column, val) => {
        console.log('handeleMeasurement: ',column, val)
        dispatch(updateMeasurement([index, column, val,parent ]))
    };

    //  const handleUngroupService = (column,val) => {
    //   console.log(typeof(val))
    //   if(val === 'true'){dispatch(updateGroup([index, column, false, parent]))
    //   }else{    dispatch(updateGroup([index, column, true, parent]))}
    //
    // };

    const handleCopy = () => { dispatch(dropElement({...dataItem, label: dataItem.label +"(Copy)"}))};

    const handleRemove = (px, cx, textId) => {
        dispatch(removeAttribute([px, cx, textId]))
    };
 

    return (
        <>   

            {dataItem.btnName === 'labelField' &&
                <>
                {/* <legend className='have-measurement-type'>  */}
                <legend> 
                <input className="form-control" value={dataItem.label} type={dataItem.labelType}
                           onChange={(e) => handleLabelName('label', e.target.value)}/>

                             
                          
                                {/* <div className='from-group'> */}
                                {/* <label for={`measurement_${index}`} >Measurement Type </label> */}
                                {/* <select name={dataItem.pricingBy} className= "form-select" id={`measurement_${index}`}
                                    onChange={(e) =>{ 
                                        // handeleMeasurement('measurement', areaSize)
                                        if(e.target.value==='areaSize'){handeleMeasurement('measurement', areaSize)}
                                        else if(e.target.value==='weight'){handeleMeasurement('measurement', weight)}
                                        else if(e.target.value==='liquidated'){handeleMeasurement('measurement', liquidated)}
                                        else{handeleMeasurement('measurement', e.target.value)}
                                    }}>
                                    <option key='' value='' selected disabled>Select Type</option>
                                    <option key='areaSize'selected={dataItem.measurement && dataItem.measurement[0].name==='areaSize'} value={'areaSize'}>Area Size</option>
                                    <option key='weight' selected={dataItem.measurement && dataItem.measurement[0].name==='weight'} value={'weight'}>Weight</option>
                                    <option key='liquidated' selected={dataItem.measurement && dataItem.measurement[0].name==='liquidated'} value={'liquidated'}>Liquidated</option>
                                </select> */}
                                {/* </div> */}
                          
                      
                        
                    {show !== true && dataItem.btnName === 'labelField' &&
                        <><OptionAddAndFromSave data={dataItem} index={index} parent={parent}/>
                        </>}
                </legend> 
                {/* <UnGroupServiceComponent data={dataItem} index={[index, parent]}/> */}
                </>
            }
            {dataItem.btnName !== 'labelField' && 
                <div className={`form-item ${dataItem.measurement && 'measurement-option'} ${show && 'active-edit'}`}>
              
                    <div className='action-area'>
                        {dataItem.btnName !== 'labelField' && <>
                            {0<parent? <span className='edit-delete-btn rank' onClick={()=>rank(index, parent , parent - 1)}><i className="fa fa-angle-up"></i></span>:
                            <span className='edit-delete-btn rank-disable'><i className="fa fa-angle-up"></i></span>}
                            {totalItem>parent+1? <span className='rank edit-delete-btn' onClick={()=>rank(index, parent , parent + 1)}><i className="fa fa-angle-down"></i></span>:
                            <span className='edit-delete-btn rank-disable'><i className="fa fa-angle-down"></i></span>}
                            <span className={`edit edit-delete-btn ${show && 'active'}`} onClick={handleShow}></span>
                            <span className='remove edit-delete-btn' onClick={() => handleRemove(index, parent, dataItem.textId)}></span> 
                            <span className='copy edit-delete-btn' onClick={() => handleCopy()} ></span> 
                            </>
                        }
                        {/* {dataItem.btnName==='labelField' && <span className='btn' onClick={()=>handleRemove(index, null)}>Remove</span>} */}
                    </div>

                    {show !== true && dataItem.btnName !== 'labelField' &&
                        <strong className='field-label'>{dataItem.label} </strong>}

                    {/* <strong className='field-label'>{dataItem.label} </strong> */}


               
                    {dataItem.measurement && <select className="measurement-form"
                    onChange={(e) =>{  
                        const currentData=measurementArr.filter((item) => item.name===e.target.value)
                        handeleMeasurement('measurement', currentData)
                    }}>
                        {measurementArr.map((item) => (<option value={item.name} selected={dataItem.measurement && dataItem.measurement[0].name===item.name}> {item.name}</option>))}
                    </select>}

                    {dataItem.btnName === 'textField' && show !== true &&
                    <>
                        <input
                            className={dataItem.ungroupService === 'enable' || dataItem.ungroupService === true ? "form-control is-ungroup-checkbox" : "form-control"}
                            value={dataItem.options} type={dataItem.labelType}
                            onChange={(e) => handleChange('options', e.target.value)}/>
                    </>}
                                {/*
                    {dataItem.btnName === 'numberField' && <input id={`field-${index}`} name={`field-${index}`} className="form-control" placeholder='dddddddd' type={dataItem.labelType} 
                    onChange={(e)=>handleChange('value',e.target.value)}/>} */}

                {dataItem.btnName === 'checkboxField' && show !== true && <>
                    {dataItem.options.map((option, r) => (
                        <div className='checkbox-field'>
                            <input type={dataItem.labelType} id={option.title} name={dataItem.label} value={option.title}/>
                            <label for={option.title}>{option.title}</label> 
                        </div>
                    ))}
                </>
                }
                {dataItem.btnName === 'radioField' && show !== true && <>
                    {dataItem.options.map((option, r) => (
                        <div className='radio-field'>
                            <input type={dataItem.labelType} id={option.title} name={dataItem.label} value={option.title}/>
                            <label for={option.title}>{option.title}</label>
                        </div>
                    ))}
                </>}

                {dataItem.btnName === 'selectField' && show !== true && <><select name={`field-${index}`}
                className={dataItem.ungroupService === 'enable' || dataItem.ungroupService === true ? "form-select is-ungroup-checkbox" : "form-select"}>
                    {dataItem.options.map((option, r) => (
                        <option key={`${option.title}_${r}`} value={option.title}>{option.title}</option>
                    ))}
                </select>
                </>}
                {show !== true && dataItem.btnName === 'comboNumberField' && <>
                    <select
                        className={dataItem.ungroupService === 'enable' || dataItem.ungroupService === true ? "form-select is-ungroup-checkbox" : "form-select"}>
                        <option key='0' value={dataItem.options + 0}>{dataItem.options}0</option>
                        {Array.from({length: dataItem.limit}, (_, index) => (
                            <option key={index + 1}
                                    value={`${dataItem.options}${index + 1}`}>{dataItem.options}{index + 1}</option>
                        ))}
                    </select>
                  
                </>}


                {dataItem.btnName === 'textareaField' && show !== true && <><textarea
                    className={dataItem.ungroupService === 'enable' || dataItem.ungroupService === true ? "form-control is-ungroup-checkbox" : "form-control"}
                    value={dataItem.options} rows="4" cols="50"
                    onChange={(e) => handleChange('options', e.target.value)}></textarea> 
                     
                </>}


                {show && dataItem.btnName !== 'labelField' &&
                    <OptionAddAndFromSave data={dataItem} index={index} parent={parent}/>}
            </div>}

        </>
    );
};

export default ItemComponent;
