import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../BaseUrl";
import { Image, Button } from "react-bootstrap";
import CustomDataTable from "../../components/CustomDataTable";

import Layout from "../../components/Layout"; 
import { useNavigate } from 'react-router-dom';
import {fetchActiveServiceItem} from '../../reducers/CategorySlice';
import {addChildInServiceItem} from "../../reducers/NavDataSlice";
import {breadcrumbTree} from "../../reducers/LocalDataSlice";
import LoadingComponent from "../../modal/LoadingComponent";
import CardViewComponent from "../../component/items/CardViewComponent";


const AreaList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [customization, setCustomization] = useState(false);
  const [manageShow, setManageShow] = useState(false);
  const [ selected, setSelected ] = useState({
        textId: null,
        title: null
      })
  const [query, setQuery] = useState('');
  const handleClose = () => {
    setShow(false); setManageShow(false);  
    // setServiceData(() => ({update: false}));
  }
  const handleShow = () => setShow(true);
  const customizationClose = () => setCustomization(false);
  const { activeItems, nav, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const navTree={'Service Area':'service-area'};
   


 

  const [customeData, setCustomeData] = useState({
    custome: false,
    id: "",
    textId: null,
  });
 


 
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData); 

    // dispatch(createService(data));
      //  handleClose(true);
 
  };


   if (nav && activeItems.length>0) {
    // console.log('addChildInServiceItem calll at area', nav)
    dispatch(addChildInServiceItem({ 'nav': activeItems, 'rank': 3 }));
  }
  useEffect(() => {
    dispatch(fetchActiveServiceItem('ServicesItemList/'));
    dispatch(breadcrumbTree(navTree));
    document.title = 'Service Area Listing';
  },[dispatch,location]);

  return (
    <Layout page_title={'Item Listing'} breadcrumb={{'Item Listing': ''}} 
            component={
        <>
        <div className='top-btn-area'> 
          {/* {!show && <button onClick={handleShow}  type="button" className="add-new-btn"> Add Service Item </button>} */}
          {!loading && activeItems.length>0 &&  activeItems.map((item) => (

              <CardViewComponent item={item} path={`${location.pathname}/${item.textId}`} navTree={navTree} />
        ))  }
      </div>
      
           
          
          {/* {query && <span>{query} | dd</span>} */}
          {/* {manageShow && <ManageCategoryService active={selected} close={handleClose} /> } */}
          {/* {customization && <AddFormModal close={customizationClose} customeData={customeData} />} */}
          {/* {show && <ServiceForm data={serviceData} close={handleClose} show={show}/> } */}
          {/* <CustomModal customizationClose={customizationClose} customeData={customeData}/> */}

         
      
           
           
          {/* {!service.loading && activeItems && !show? <CustomDataTable data={activeItems} columns={columns} /> : null} */}
            {loading && <LoadingComponent message={''}/>}
        </>
      }
    ></Layout>
  );
};

export default AreaList;
